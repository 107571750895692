.cart-items {

	li {
		@include span-columns(12);
		border: 1px solid color('gray');
		margin-bottom: 20px;

		.col-1,
		.col-2 {
			float: left;			
		}

		.col-1 {
			width: 35%;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-right: 0;
		}

		.col-2 {	
			width: 65%;
			padding: 15px 0;
		}

		.product-image {

			img {
				margin: 0 auto;
				width: 80px;
			}
			
		}

		.product-info {
			float: left;
			width: 60%;
    		padding-right: 10px;

			.product-name {
				display: block;
				font-size: 14px;
				padding-bottom: 10px;
				line-height: 16px;
			}

			.product-sku {
				font-size: 11px;
				color: color('gray', 'dark');
			}

		}

		.product-price {
			float: left;			
			width: 40%;
		}

		.product-qty {
			float: left;
			width: 60%;
			text-align: right;

			.qty {
				text-align: left;
				
				input {
					width: 40px;
				}

			}

		}

		.product-qty,
		.product-total {
			margin-top: 20px;
		}

		.product-total {
			float: left;			
			width: 40%;			
		}

		.price {
			font-size: 16px;
			line-height: 20px;
			font-weight: bold;
			color: color('orange');
		}

		.btn-remove {
			@include span-columns(12);
			font-size: 11px;
			color: color('white');
			background: color('gray', 'dark');
		}		

	}

}	