a {
	background: color('orange');
    display: block;
    position: relative;

    .count {
    	position: absolute;
    	height: 25px;
    	width: 25px;
    	line-height: 25px;
    	font-size: 12px;
    	text-align: center;
    	background: color('black', 'light');
    	color: color('white');
		top: 24%;
		left: -12px;   	
    }

	.icon-cart {
	    font-size: 18px;
	    line-height: 50px;
	    display: block;
	    text-align: center;
	    color: color('black');
	}

}	