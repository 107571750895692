@font-face {
    font-family: 'icomoon';
    src: url('../../../santuario/default/fonticon/icomoon.eot');
    src: url('../../../santuario/default/fonticon/icomoon.eot?#iefix') format('embedded-opentype'),
    url('../../../santuario/default/fonticon/icomoon.woff') format('woff'),
    url('../../../santuario/default/fonticon/icomoon.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons: (
    "benefits" : '\e900',
    "bible" : '\e901',
    "billet" : '\e902',
    "book" : '\e903',
    "card" : '\e904',
    "cart" : '\e905',
    "ebooks" : '\e906',
    "gift" : '\e907',
    "newspapper" : '\e908',
    "phone" : '\e909',
    "sale" : '\e90a',
    "search" : '\e90b',
    "shipping" : '\e90c',
    "user" : '\e90d',
    "wine" : '\e90e',
    "arrow" : '\e90f',
    "facebook" : '\e910',
    "instagram" : '\e911',
    "twitter" : '\e912',
    "youtube" : '\e913',
    "navigation" : '\e914',
    "eye" : '\e915',
    "trash" : '\e916',
    "discount" : '\e917',
    "card-secure" : '\e918',
    "check" : '\e919',
    "lock" : '\e91a',
    "edit" : '\e91b',
    "print" : '\e91c',
    "catalog" : '\e91d',
    "idea" : '\e91e',
    "headset" : '\e91f',
    "star" : '\e920',
    "sac-chat" : '\e922',
    "sac-mail" : '\e923',
    "sac-phone" : '\e924' 
);

@each $icon, $content in $icons {

    .icon-#{$icon} {

        &:before {
            content: '#{$content}';
        }

    }

}