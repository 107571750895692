.bar {
	@include span-columns(12);
	@extend .vertical-scroll;
	line-height: 30px;
	background: color('gray', 'light');
	text-align: center;
	border-bottom: 1px solid color('gray');

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 20px;
		text-transform: uppercase;
		border-right: 1px solid color('gray');

		&:last-child {
			border-right: 0;
		}

		span {
			color: color('orange');
			margin-right: 15px;
			font-size: 15px;
			vertical-align: middle;
		}

	}

}