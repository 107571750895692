#checkout-review-submit {
    @extend .no-display;
}

#payment_form_pagarme_cc,
#payment_form_webjump_braspag_cc,
#payment_form_webjump_braspag_multi_cccc_one_card,
#payment_form_webjump_braspag_multi_cccc_two_card {
    @include span-columns(12);

    li {

        &.card-number {
            margin-top: 20px;
        }

        &.card-name {
            @include span-columns(12);
        }

        &.card-exp {
            @include span-columns(12);

            .v-fix {
                @include span-columns(6);

                select {
                    margin-bottom: 20px;
                }

            }

        }

        &.card-cvv {
            @include span-columns(12);
        }

        &.card-installments {
            @include span-columns(12);
        }

    }

}

#payment_form_webjump_braspag_multi_cccc_one_card,
#payment_form_webjump_braspag_multi_cccc_two_card {

    li {
    
        &.card-total {
            @include span-columns(6);                       
        }
    
    }

}

#card-name-flag {

    ul li {

        float: left;
        display: none;

        &.on {

            display: block;
        }
    }
}