.header-logo {
	@include span-columns(6);
	text-align: left;

	img {
		margin: 7px $gapp;
		width: 70px;
	}

}

.header-secure {
	@include span-columns(3.5);
	position: relative;
	padding-top: 8px;
	padding-right: $gapp;
	font-size: 12px;
	text-transform: uppercase;
	float: right;

	span, p {
		display: inline-block;
		vertical-align: middle;
	}

	span {
		position: absolute;
		top: 10px;
		font-size: 18px;
	}

	p {
		padding-left: 20px;
	}

}