.customer-account-login {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('orange');
			padding-bottom: 0;
			margin: 20px 0 5px 0;
		}

		p {
			font-size: 16px;
			line-height: 20px;
		}

	}

	.customer-form {
		@include span-columns(12);

		h3 {
			font-weight: bold;
			display: block;
			margin: 20px 0;
			font-size: 16px;
			text-transform: uppercase;
			color: color('orange');
		}

		.col-1, .col-2 {
			@include span-columns(12);
		}

		.col-1 {
			border-bottom: 2px solid color('orange');

			.button {
				@extend %green;
			}

		}

		.col-2 {

			.button {
				@extend %black;
			}

		}
		
		.description {
			line-height: 24px;
		}

		.password {

			.input-box {
				margin-bottom: 10px;
			}

		}

		.buttons-set {
			@include span-columns(12);
			margin: 20px 0;
		}

	}

}

.customer-account-logoutsuccess {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('orange');
			padding-bottom: 20px;
		}

		p {
			font-size: 16px;

			span {
				font-weight: bold;
				color: color('orange');
			}

		}

	}

	.main {
		margin: 50px 0 100px 0;
		min-height: 200px;
	}

}